import {Component} from '../../component';
import {Utils} from "../../utils";

export class SimpleTabs extends Component {
    private buttons: Array<HTMLElement>;
    private contents: Array<HTMLElement>;
    private activeTab: any;

    constructor (element) {
        super(element);
    }

    protected onMounted (): void {
        this.initElements();
        this.initEvents();
    }

    protected onUnmounted (): void {
        this.destroyEvents();
    }

    public setActiveTab (button: HTMLElement): void {
        const selectedTabId = button.getAttribute('data-content-id');
        if (selectedTabId !== this.activeTab) {
            this.clearActiveTab();
            for (let i = 0; i < this.contents.length; i++) {
                const content = <HTMLElement>this.contents[i];
                if (content.getAttribute('data-tabs-content') === selectedTabId) {
                    content.classList.add('active');
                    button.classList.add('active');
                }
            }
            this.activeTab = selectedTabId;
        }
    }

    private initElements (): void {
        this.buttons = this.getRefs('button');
        this.contents = this.getRefs('content');
    }

    private initEvents (): void {
        for (let i = 0; i < this.buttons.length; i++) {
            const button = this.buttons[i];
            Utils.addEvent(button,'click', (event) => {
                event.preventDefault();
                this.setActiveTab(button);
            });
        }
    }

    private destroyEvents (): void {

    }

    private clearActiveTab (): void {
        for (let i = 0; i < this.buttons.length; i++) {
            const button = <HTMLElement>this.buttons[i];
            button.classList.remove('active');
        }

        for (let i = 0; i < this.contents.length; i++) {
            const content = <HTMLElement>this.contents[i];
            content.classList.remove('active');
        }
    }
}
