import {Utils} from "../../utils";

export class CookiesMessage {
    public cookiesBox: HTMLElement;
    public closeButton: HTMLElement;

    constructor () {
        this.initializeElements();
        this.initializeEvents();
    }

    initializeElements () {
        this.cookiesBox = document.querySelector('[data-selector="cookies"]');
        this.closeButton = document.querySelector('[data-selector="closeCookies"]');
        if (!Utils.getCookie('asekuracjaCookies') && this.cookiesBox) {
            this.cookiesBox.style.display = 'block';
        }
    }

    initializeEvents () {
        Utils.addEvent(this.closeButton, 'click', event => {
            if (this.cookiesBox) {
                this.cookiesBox.style.display = 'none';
            }
            Utils.setCookie('asekuracjaCookies', 'asekuracjaCookies', {expires: 365});
        });
    }
}
