import * as $ from 'jquery';
import * as jcf from 'jcf';

export class Base {
    constructor () {
        new rwdMenu ();
        new accordeon ();
        new customForm();
    }
}

class rwdMenu {
    constructor () {
        this.initialize();
    }

    initialize () {

        $('.menu-primary__rwd-btn').on('click', function (e) {
            e.preventDefault();
            var $menu = $('.menu-primary__list');
            $menu.toggleClass('list--active');
            $('body').toggleClass('lock');
        });
    }
}

class accordeon {
    constructor () {
        this.initialize();
    }

    initialize () {

        var $link = $('.accordeon__link');

        $link.on('click', function (e) {
            e.preventDefault();
            var $self = $(this);
            var $item = $self.closest('.accordeon__item');
            var $content = $('.accordeon__content', $item);

            $item.toggleClass('item--active');

            if ($item.hasClass('item--active')) {
                $content.slideDown(300);
            } else {
                $content.slideUp(300);
            }
        });
    }
}

class customForm {
    constructor () {
        this.initialize();
    }

    initialize () {
        jcf.setOptions({
            focusClass: 'h-focus'
        });
        jcf.setOptions('Select', {
            wrapNative: false,
            maxVisibleItems: 8
        });

        const elements = document.querySelectorAll('[jcf]');
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            jcf.replace(element);
        }
        const requiredCb = document.querySelectorAll('.jcf-checkbox + .required');

        for (let i = 0; i < requiredCb.length; i++) {
            const node = document.createElement('SPAN');
            node.classList.add('required');
            node.innerHTML = '*';

            const len = requiredCb[i].querySelectorAll('*:last-child')[0];
            len.appendChild(node);
        }

    }
}